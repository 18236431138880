.row{
  display: flex;
}
.flip-size{
  width: 380px;
  height: 450px;   
  margin: 10px 20px;
}
.flip {
  text-align: center;
  perspective: 600px;  
  display: flex;
}
  .flip-content {
    width: 100%;
    height: 100%;
    transition: transform 0.4s;
    transform-style: preserve-3d;
  }
  .flip:hover .flip-content {
    transform: rotateY(180deg);
    transition: transform 0.3s;
  }

  .flip-front, .flip-back {
    position: absolute;
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    background-color: rgb(72, 96, 231);
    color: #fff;  
    border: 1px solid rgb(255, 255, 255);
  }
  .flip-front img{
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .flip-back p{
    padding: 10px 05px;
    line-height: normal;
    color: white;
  }
  .flip-front, .flip-back {
    position: absolute;
    height: 100%;
    width: 100%;
    line-height: 150px;
    backface-visibility: hidden;  
    color: #fff;  
    background-color: rgb(15, 36, 156);
    border: 6px solid rgb(238, 238, 238);
  }
  .flip-back {
    transform: rotateY(180deg);
    box-shadow: 6px 6px rgb(228, 189, 16);
  }