.react-slideshow-container .default-nav svg {
    color: black !important;
    margin-right: -20px;
    margin-top: -9px;
}

.slide-container {
    background-color: rebeccapurple;
    position: relative;
}

.bg-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.image-description {
    width: calc(100% - 300px);
    color: rgb(6, 10, 223);
}

@media screen and (max-width: 700px) {
    .image-description {
        width: 200px;
        font-size: 14px;
    }
}

header {
    position: absolute;
}

header h4 {
    color: white;
}

nav a {
    color: white;

}